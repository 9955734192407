.business-values {
  border-left: 3px solid;
}

.btn-c-o-primary {
  border: 2px solid $c-primary;
  color: $c-primary;
}

.btn-c-o-primary:hover {
  background-color: $c-primary;
  border: 2px solid $c-primary;
}
