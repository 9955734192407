.btn-rounded {
  border-radius: 2rem;
  border: 2px solid;
}

/*-----------------------*/
/*-- outline-c-primary --*/
/*-----------------------*/
.btn-outline-c-primary {
  color: $c-primary;
  border-color: $c-primary;
}

.btn-outline-c-primary.active {
  background-color: $c-primary;
}

.btn-outline-c-primary:hover {
  background-color: $c-primary;
  color: #fff;
}

.btn-outline-c-primary:focus,
.btn-outline-c-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(12, 11, 55, 0.5);
}

.btn-outline-c-primary.disabled,
.btn-outline-c-primary:disabled {
  color: $c-primary-d;
  background-color: transparent;
}

.btn-outline-c-primary:not(:disabled):not(.disabled):active,
.btn-outline-c-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-c-primary.dropdown-toggle {
  color: #fff;
  background-color: $c-primary;
  border-color: $c-primary-l;
}

.btn-outline-c-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-c-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-c-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(12, 11, 55, 0.5);
  background-color: $c-primary;
  border-color: $c-primary-d;
}

/*-------------------*/
/*-- btn secondary --*/
/*-------------------*/
.btn-c-secondary {
  color: white;
  border-color: $c-secondary-d;
  background-color: $c-secondary;
}

.btn-c-secondary:hover {
  background-color: $c-primary;
  color: #fff;
}

.btn-c-secondary:focus,
.btn-c-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(12, 11, 55, 0.5);
}

.btn-c-secondary.disabled,
.btn-c-secondary:disabled {
  color: $c-secondary-l;
  background-color: transparent;
}

.btn-c-secondary:not(:disabled):not(.disabled):active,
.btn-c-secondary:not(:disabled):not(.disabled).active,
.show > .btn-c-secondary.dropdown-toggle {
  color: #fff;
  background-color: $c-secondary;
  border-color: $c-secondary-l;
}

.btn-c-secondary:not(:disabled):not(.disabled):active:focus,
.btn-c-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-c-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(12, 11, 55, 0.5);
}

/*-----------------------*/
/*-- BTN FORM MESSAGES --*/
/*-----------------------*/

.btn-form.message {
  position: relative;
  border-radius: 2rem;
  position: absolute;
  right: 3%;
  height: 2rem;
  border: none;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

@media (max-width: 1450px) {
}
