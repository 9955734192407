.header-1 {
  position: absolute;
  top: 1%;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-1 nav {
  width: 100%;
}

.nav-link-listener.text-c-primary {
  font-weight: bold;
}

#navbar-slider {
  z-index: $z-fixed;
  top: 5%;
}

.z-nav {
  z-index: $z-fixed;
}

.bg-navbar {
  background: rgb(251, 251, 251);
  background: linear-gradient(
    90deg,
    rgba(251, 251, 251, 0.1) 0%,
    rgba(251, 251, 251, 0.59) 19%,
    rgba(251, 251, 251, 0.79) 30%,
    rgba(251, 251, 251, 0.82) 75%,
    #fbfbfb 85%
  );
  /*
  background: linear-gradient(
    90deg,
    rgba(251, 251, 251, 0.19) 5%,
    rgba(251, 251, 251, 0.25257181677018636) 30%,
    rgba(251, 251, 251, 0.5041246118012422) 60%,
    rgba(251, 251, 251, 1) 85%
  );*/
}

.social-link {
  background-color: #fbfbfb;
  border-radius: 50%;
  cursor: pointer;
  width: 22px;
  height: 22px;
  text-align: center;
  font-weight: bold;
}

/*------------------------------------*/
/*------ Basic Underline Styles ------*/
/*------------------------------------*/

.underline-box {
  position: relative;
  display: block;
  width: 50px;
  text-align: center;
  cursor: pointer;
  height: auto;
}

.underline-box a {
  font-size: 12px;
  line-height: 15px;
}

.custom-underline {
  color: $c-primary;
  position: relative;
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.custom-underline,
.custom-underline:hover,
.custom-underline:focus,
.custom-underline:active {
  text-decoration: none;
}

.custom-underline::after {
  content: "";
  position: absolute;
}

/*------------------------------------*/
/*--- Individual Custom Underlines ---*/
/*------------------------------------*/

/*--- Green Box ( underline-box ) ---*/
.underline-box .custom-underline::after {
  align-items: center;
  top: 90%;
  height: 2px !important;
  width: 50%;
  left: 25%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.4s ease-out all 0.1s;
}

.underline-box:hover .custom-underline::after {
  width: 10%;
  transition: 0.5s ease all;
}

.bg-navbar-2 {
  background-color: $c-primary;
  color: white;
  max-width: 100vw;
}

.nav-hover:hover {
  background-color: $c-secondary-d;
  border-radius: 0.3rem;
}
