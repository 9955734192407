@import "../components/navbar";
@import "../components/slider";
@import "../components/brand";

.bussines-image figure {
  position: relative;
  top: 25%;
}

.bussines-image img {
  border-radius: 0.8rem;
}

.btn-business-normalize {
  line-height: 35px;
  width: 150px;
}

.vmv-bussines {
  position: absolute;
  left: -50%;
  max-width: 60%;
  display: none;
}

.how-are-us {
  text-align: left;
  margin: 0 0 0 5rem;
}
