#slider {
  height: 100%;
}

.img-slider-container {
  margin: 0;
}

.img-slider-container img {
  max-height: 100vh;
  height: 100%;

  object-fit: cover;
  opacity: (0);
  transition: all 1s ease;
}

@media (max-width: 768px) {
  .img-slider-container img {
    max-height: 50vh;
  }
}

.active {
  opacity: (1) !important;
}

.non-active {
  opacity: (0);
}

#carousel-custom-dots {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
}

/* --------------------- */
/* --- Pages Buttons --- */
/* --------------------- */

.pages {
  position: absolute;
  top: 0;
  margin-left: 20px;
}

.pages__list {
  list-style-type: none;
  transform: translateY(30vh);
}

.pages__item {
  position: relative;
  margin-bottom: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #fff;
  cursor: pointer;
}

.pages__item:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0, 0);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
  transition: 500ms;
}

.pages__item:hover:after {
  transform: translate(-50%, -50%) scale(1, 1);
  opacity: 1;
}

.page__item-active:after {
  transform: translate(-50%, -50%) scale(1, 1);
  opacity: 1;
}

.owl-dots {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
}

.owl-dot.active > span {
  background-color: $c-secondary !important;
}

.owl-dot > span {
  background-color: $c-primary-l !important;
}
