$max-width: 1200px;

/* ---------------*/
/* ----Z-Index----*/
/* ---------------*/
$z-back: -10;
$z-normal: 1;
$z-tooltip: 10;
$z-fixed: 100;
$z-modal: 1000;

:root {
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* ---------------*/
/* -----Images----*/
/* ---------------*/
$images-path: "../assets";

/* ---------------*/
/* -----Colors----*/
/* ---------------*/
/*$c-primary: #0c0b37;*/
$c-primary: #101238;
$c-primary-l: lighten($c-primary, 10%);
$c-primary-d: darken($c-primary, 10%);

/*$c-secondary: #ff612d;*/
$c-secondary: #f16336;
$c-secondary-l: lighten($c-secondary, 10%);
$c-secondary-d: darken($c-secondary, 10%);

$c-three: #acacac;

/*--- OThers ---*/
$c-primary-dg-1: #2a4877;
$c-primary-dg-2: #2b71a4;
$c-secondary-dg-1: #f16336;
$c-secondary-dg-2: #f7931d;

/* --------------------*/
/* -----BreakPoints----*/
/* --------------------*/
/*

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { ... }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { ... }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { ... }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { ... }

*/
