.bg-allies-card {
  display: inline-block;
  background: $c-secondary;
  background: radial-gradient(
    circle,
    rgba(247, 154, 30, 1) 0%,
    rgba(210, 75, 42, 1) 60%,
    rgba(189, 46, 40, 1) 80%
  );
}

.bg-section-brand {
  background: radial-gradient(circle, rgba(40, 100, 152, 1) 0%, rgba(25, 37, 77, 1) 60%, $c-primary 80%);
}

.bg-brands-card {
  display: inline-block;
  // background: $c-primary;
  background: transparent;
  border: none;
}

.product-text {
  max-width: 700px;
}

@media (max-width: 1199.98px) {
  .product-text {
    max-width: 700px;
  }
}

.for-text-product {
  height: 253px;
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 575.98px) {
}

/* ---------------------- */
/* --- Brands Sliders --- */
/* ---------------------- */

.owl-item-drag {
  cursor: grab;
}

.arrow-nav-left-carousel {
  position: absolute;
  left: -24.5rem;
  top: 50%;
  width: 100%;
}

.arrow-nav-right-carousel {
  position: absolute;
  left: 24rem;
  top: 50%;
  width: 100%;
}

.product-img {
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-img img {
  max-height: 23rem;
  width: 270px !important;
}

@media screen and (max-width: 991.98px) {
}

@media screen and (max-width: 767.98px) {
}

@media screen and (max-width: 575.98px) {
}
