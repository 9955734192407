.navbar-mobile {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  position: fixed;
  transition: all 0.3s ease-in;
  clip-path: circle(0% at 0% 0%);
}

.navbar-mobile.active {
  clip-path: circle(100% at 50% 50%);
}

@media (orientation: landscape) {
  .ul-mobile {
    margin-top: 3rem;
  }

  li.mobile-nav {
    font-size: 1rem;
  }
}
