html,
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  color: $c-primary;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  body {
    font-size: 3rem;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 575.98px) and (max-width: 991.98px) {
  body {
    font-size: 2rem;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (min-width: 991.98px) and (max-width: 1199.98px) {
  body {
    font-size: 1.3rem;
  }
}

.overflow-restore {
  overflow-y: scroll;
}

textarea {
  resize: none;
  height: 12rem !important;
  border-radius: 1rem;
  padding: 1rem;
}

textarea:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.nav-link {
  transition: 0.3s;
}

.nav-link:hover {
  color: white !important;
  text-shadow: -2px 1px $c-primary;
}

/* ----------------- */
/* ----- Utils ----- */
/* ----------------- */

.section-h-c {
  transition: all 0.5s;
}

.input-custom-c {
  border-radius: 0.75rem;
  background: rgb(248, 239, 239);
  border: rgb(248, 239, 239);
}

.rounded-hard {
  border-radius: 1rem;
}

.rounded-xl {
  border-radius: 2rem;
}

.spacing-letter-soft {
  letter-spacing: 0.3rem;
}

.bg-c-primary {
  background-color: $c-primary;
}

.bg-c-secondary {
  background-color: $c-secondary;
}

.bg-c-three {
  background-color: $c-three;
}

.bg-c-primary-dg {
  background: radial-gradient(circle, rgba(40, 100, 152, 1) 0%, rgba(25, 37, 77, 1) 60%, $c-primary 80%);
}

.bg-c-secondary-dg {
  background: radial-gradient(
    circle,
    rgba(247, 154, 30, 1) 0%,
    rgba(210, 75, 42, 1) 60%,
    rgba(189, 46, 40, 1) 80%
  );
}

.text-bold {
  font-weight: bold;
}

.text-c-primary {
  color: $c-primary !important;
}

.text-c-secondary {
  color: $c-secondary;
}

.text-c-three {
  color: $c-three;
}

.section-padding-3 {
  padding: 3rem;
}

.section-full-h {
  height: 100%;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  /*.section-full-h {
    height: 50vh;
  }*/
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  /*.section-full-h {
    height: 70vh;
    max-height: 100%;
  }*/
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}
