.arrow-nav-left-carousel-news {
  position: absolute;
  right: 50%;
  top: 50%;
  width: 100%;
}

.arrow-nav-right-carousel-news {
  position: absolute;
  left: 49%;
  top: 50%;
  width: 100%;
}

.new-img {
  /*max-width: 500px;*/
  text-align: center;
}

.new-img img {
  display: inline-block !important;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 769px) {
  .new-img {
    max-width: 400px;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .arrow-nav-left-carousel-news {
    right: 45%;
  }

  .arrow-nav-right-carousel-news {
    left: 45%;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}
