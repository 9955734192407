.subscribe {
  border: none;
  outline: none;
}

footer.subscribe {
  max-width: 15rem;
}

#subscribe.focus,
#subscribe.active,
#subscribe:focus,
#subscribe:active {
  border: none;
  outline: none;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .subscribe-text {
    font-size: 3rem;
  }
}
